/* eslint-disable @angular-eslint/component-selector */
import { Component, inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'ab-youtube-video',
  standalone: true,
  template: `
    <div class="youtube-container">
      <iframe
        [src]="safeYoutubeUrl"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  `,
  styleUrl: './ab-youtube-video.component.scss',
  imports: [],
})
export class AbYoutubeVideoComponent implements OnInit {
  private youtubeUrl = inject(NZ_MODAL_DATA);
  private sanitizer = inject(DomSanitizer);

  safeYoutubeUrl: SafeResourceUrl;

  ngOnInit(): void {
    this.safeYoutubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.youtubeUrl,
    );
  }
}
